<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-card-title
          title-tag="h2"
          class="font-weight-bold mb-2 text-center"
        >
          <b-link class="text-center">
            <img
              :src="logoImg"
              height="100"
              weight="80"
              alt=""
              srcset=""
            >
          </b-link>
          <br>
        </b-card-title>

        <b-card-title class="mb-1 text-center">
          Confirmation de mail
        </b-card-title>
        <b-card-text class="mb-2">
          Veuillez patienter durant la confirmation de votre compte
        </b-card-text>

        <div class="text-center">
          <b-spinner
            v-if="process"
            class="mr-1"
            variant="primary"
          />
        </div>

        <div class="divider my-2">
          <div class="divider-text text-primary font-weight-bold h5">
            Godwin Multiservice
          </div>
        </div>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
  },
  data() {
    return {
      loaded: false,
      userEmail: null,
      callBackUrl: `${window.location.hostname}/reset-password`,
      // eslint-disable-next-line global-require
      logoImg: require('@/assets/images/logo/logo-sombre.svg'),
    }
  },
  computed: {
    ...mapGetters({
      process: 'confirmEmailProcess',
      error: 'confirmEmailError',
      success: 'confirmEmailSuccess',
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.confirmEmail()
      },
    },
    success(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('confirm.title'),
            text: this.$t('confirm.success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })

        setTimeout(() => {
          this.$router.push({ name: 'login' })
        }, 2000)
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('confirm.title'),
            text: this.$t('confirm.error'),
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['confirmUserEmail']),
    async confirmEmail() {
      this.confirmUserEmail({
        token: this.$route.params.token,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
